<template>
  <div>
    <div v-show="shopeeToken==null">
      身分未經授權<br/>請勿將此網址加入我的最愛，請至<a href="https://www.invoicego.tw/login/?next=/">發票通管理平台</a>進行身分授權或請洽管理員
      <br/>若登入再次失敗請將瀏覽器上方網址複製給管理員回報，我們將盡快協助處理問題
      <br/>
      {{this.$route.query.code !=undefined ? ('(error code:'+this.$route.query.code +')'): ''}}
    </div>
    <b-tabs v-show="shopeeToken!=null">
      <b-tab title="資料匯入" v-show="shopeeToken!=null">
          <b-card>
            <b-overlay :show="show" rounded="sm">
              <b-row>
                <b-col :cols="2">
                  <img :src="sppngSrc" alt="Logo" style="height:40px;"/>
                </b-col>
                <b-col :cols="7">
                  <b-form-group label="開啟Excel密碼:" label-for="file-small" label-cols-sm="4">
                    <b-form-input v-model="excelPwd"></b-form-input>
                  </b-form-group>
                  <b-form-group label="蝦皮退貨明細上傳區:" label-for="file-small" label-cols-sm="4">
                    <b-form-file browse-text="選擇訂單Excel檔" id="orderInv" v-model="fileSpOrder" 
                    accept="application/vnd.ms-excel" 
                    placeholder="檔案拖移此處" drop-placeholder="Drop file here..." />
                  </b-form-group>
                </b-col>
                <b-col :cols="3">
                  <b-button variant="success" @click="uploadShOrderXlsx()">上傳</b-button>
                  <!-- <div v-show="false">
                    <input type="text" v-model="jsonFileName">
                    <b-button @click="startLogForDev()">重新紀錄</b-button>
                  </div> -->
                </b-col>
              </b-row>
            </b-overlay>
          </b-card>
      </b-tab>
      
    </b-tabs>

  </div>
</template>

<script>

import {
  BCard, BCardText, BCol, BRow, BFormGroup, BFormInput, BButton,BTab,BTabs,BFormFile,BOverlay,BTable,BPagination,BFormCheckbox,BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCol,
    BTab,
    BTabs,
    BRow,
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,BTable,BPagination,BFormCheckbox,BAlert,
    vSelect,
    BFormFile,
  },
  data() {
    return {
      shopeeToken: null,
      show: false,
      name: '',
      taxType: null,
      q: {
        qInvNo: '',
        qOrderNo: '',
        qCusNo:'',
      },
      fileSpInv: null,
      fileSpOrder: null,
      excelPwd:'',
      fileSpIncome: null,
      token: null,
      cusNo: '',
      user: {
        cusNo: '',
        userId: '',
      },
      selectRowSpOrder:null,
      orderTableData:[],
      jsonFileName:'',
      sppngSrc:process.env.BASE_URL + '/ordertype/sp.png',
    }
  },
  created() {
    let userData = { ability: [
      {action:'shopee',subject:'Auth'},
    ]}
    this.$ability.update(userData.ability)
    // 從session來的
    let se = JSON.parse(sessionStorage.getItem('se'))
    if(se==null){
      return
    }

    this.shopeeToken = se.shopeeToken
    this.user.cusNo = se.cusNo
    this.user.userId = se.accountId
    this.q.qCusNo = se.cusNo
  },
  methods: {

    parseDate(d){
      if(d!=null && d.length >= 12){
        return d.substring(4,6) + '/' +d.substring(6,8) + ' ' +d.substring(8,10) + ':'+d.substring(10,12) 
      }
      return ''
    },

    getFormatDate(str ,type){
      if(type == 'mm/dd hh:mm') {
        if( str!= null) {
          return str.substring(4,6)+'/'+str.substring(6,8)+' '+ str.substring(8,10)+':'+str.substring(10,12)
        }
      } else if(type == 'yyyy/mm/dd hh:mm') {
        if(str!= null) {
          return str.substring(0,4)+'/'+str.substring(4,6)+'/'+str.substring(6,8)+' '+ str.substring(8,10)+':'+str.substring(10,12)
        }
      }
    },

    uploadShOrderXlsx(){
      // if(true){ return }
      if(this.excelPwd == ''){
        this.$bvToast.toast('錯誤', {
          title: `請輸入蝦皮開啟excel密碼`,
          toaster: 'b-toaster-top-center' ,
          autoHideDelay: 1000,
          variant: 'danger',
          solid: false,
          appendToast: false,
        })
        return
      }
      
      if(this.fileSpOrder == null) {
        this.$bvToast.toast('錯誤', {
          title: `請選擇要上傳的檔案`,
          toaster: 'b-toaster-top-center' ,
          autoHideDelay: 1000,
          variant: 'danger',
          solid: false,
          appendToast: false,
        })
        return
      }
      
      let headers = {
        'Accpet': 'application/json',
        'content-type' : 'multipart/form-data',
        Authorization: this.token,
      }
      let user = store.state.commonParam.user
      this.formData = new FormData()
      this.formData.append('fileSpOrder', this.fileSpOrder)
      this.formData.append('shopeeToken', this.shopeeToken)
      this.formData.append('pwd', this.excelPwd)
      
      this.show = true
      this.$http.post('../api/shopeeReturn/uploadShopeeReturnXlsx', this.formData, {headers}).then(res => {
        this.show = false
        if(res.data.msg == 'success') {
          this.fileSpOrder  = null
          this.$toast({ component: ToastificationContent,
                props: {title: '上傳完成', variant: 'success', autoHideDelay: 2000, icon:'none'},
              })
        } else {
          this.$toast({ component: ToastificationContent,
              props: {title: res.data.data, variant: 'danger', autoHideDelay: 10000, icon:'none'},
            })        
        }
 
      }).catch(() =>{
        this.show = false
        this.$bvToast.toast('錯誤', {
          title: `上傳錯誤`,
          toaster: 'b-toaster-top-center' ,
          autoHideDelay: 5000,
          variant: 'danger',
          solid: false,
          appendToast: false,
        })
      })
    },
  },
  computed:{
  }
}
</script>

<style lang="scss" >
@import '~@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@/assets/scss/style.scss';

.history-table{
  border: 1px solid white;
  border-collapse: collapse;
}
// .dark-layout label {
//     font-size: 16px;
// }
</style>
